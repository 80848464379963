import * as React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  const publications = data.publication.nodes

  const blogs = data.blog.nodes

  if (blogs.length && publications.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        <Bio />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Home" />
      <Bio />

      {renderPublicationList(publications)}
      {renderBlogList(blogs)}
    </Layout>
  )
}

export default BlogIndex

// render functions

let renderPublicationList = publications => {
  return (
    <>
      <h2>Publication</h2>
      <ol style={{ listStyle: `none`, marginLeft: "50px" }}>
        {publications.map(post => {
          const title = post.frontmatter.title || post.fields.slug

          return (
            <li key={post.fields.slug}>
              <article
                style={{ display: "flex" }}
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <div style={{ marginRight: "2rem" }}>
                  <Img
                    fixed={
                      post.frontmatter.thumbnail.childrenImageSharp[0].fixed
                    }
                    alt="Gatsby Docs are awesome"
                  />
                </div>
                <div>
                  <header>
                    <h2>
                      <Link to={post.fields.slug} itemProp="url">
                        <span itemProp="headline">{title}</span>
                      </Link>
                    </h2>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: post.frontmatter.description || post.excerpt,
                      }}
                      itemProp="description"
                    />
                  </header>
                  <section>
                    <small>{post.frontmatter.date}</small>
                  </section>
                </div>
              </article>
            </li>
          )
        })}
      </ol>
    </>
  )
}

let renderBlogList = blogs => {
  return (
    <>
      <h2>Project</h2>
      <ol style={{ listStyle: `none`, marginLeft: "50px" }}>
        {blogs.map(post => {
          const title = post.frontmatter.title || post.fields.slug

          return (
            <li key={post.fields.slug}>
              <article
                style={{ display: "flex" }}
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <div style={{ marginRight: "2rem" }}>
                  <Img
                    fixed={
                      post.frontmatter.thumbnail.childrenImageSharp[0].fixed
                    }
                    alt="Gatsby Docs are awesome"
                  />
                </div>
                <div>
                  <header>
                    <h2>
                      <Link to={post.fields.slug} itemProp="url">
                        <span itemProp="headline">{title}</span>
                      </Link>
                    </h2>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: post.frontmatter.description || post.excerpt,
                      }}
                      itemProp="description"
                    />
                  </header>
                  <section>
                    <small>{post.frontmatter.date}</small>
                  </section>
                </div>
              </article>
            </li>
          )
        })}
      </ol>
    </>
  )
}

// graphql
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    blog: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "blog" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM YYYY")
          title
          description
          thumbnail {
            childrenImageSharp {
              fixed(width: 120, height: 120) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }

    publication: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "publication" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM YYYY")
          title
          description
          thumbnail {
            childrenImageSharp {
              fixed(width: 120, height: 120) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`
